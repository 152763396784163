import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import InputForm from './components/InputForm';
import { useUser } from './UserContext';
import Modal from './components/Modal';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import PrivacyPolicy from './components/PrivacyPolicy';
import { getCurrentUser, fetchAuthSession, signOut, fetchUserAttributes } from 'aws-amplify/auth';
import { ApiError, post, get } from 'aws-amplify/api';
// import StripeButton from './components/StripeButton';

Amplify.configure(amplifyconfig);

const App = () => {
  const { user, setUser } = useUser();
  const [coverLetter, setCoverLetter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);

  // Toggle expanded description
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  // Utility function to fetch ID token
  const fetchIdToken = async () => {
    const authSession = await fetchAuthSession();
    return authSession.tokens?.idToken?.toString();
  };

  // Fetch user attributes from Cognito message comes when status=!paid? make two different messages, one for not logged in and one for not paid. 
  const handleFetchUserAttributes = async () => {
    if (!user) return;

    try {
      const userAttributes = await fetchUserAttributes();
      const hasPaidAttribute = userAttributes.find(attr => attr.Name === 'custom:paid')?.Value === 'true';
      setHasPaid(hasPaidAttribute);
    } catch (error) {
      console.error('Error fetching user attributes:', error);
      setError('Unable to fetch user attributes.');
    }
  };

  useEffect(() => {
    handleFetchUserAttributes();
  }, [user]);

  // useEffect for polling
  useEffect(() => {
    let interval;
    if (isPolling) {
      interval = setInterval(fetchCoverLetter, 1000);
    }
    return () => clearInterval(interval); // Cleanup
  }, [isPolling]); // Dependency array

  // Signout
  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  // POST Method Generate Cover Letter
  async function generateCoverLetter(formData) {
    if (!user) {
      setShowModal(true);
      return; // Exit early if not logged in
    }

    setIsLoading(true);
    setError('');
    setCoverLetter('');

    const idToken = await fetchIdToken();

    if (!idToken) {
      setShowModal(true);
      setError("User is not authenticated.");
      setIsLoading(false);
      return; // Exit the function early
    }

    // If authenticated proceed
    try {
      const restOperation = await post({
        apiName: 'cligAPI',
        path: '/api/generate-cover-letter',
        options: {
          body: formData,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          }
        }
      });

      // Body is in JSON format and includes a statusCode
      const response = await restOperation.response;

      // Check if the operation is successfully initiated
      if (response && response.statusCode === 202) {
        setIsPolling(true);
      } else {
        setError('Failed to generate cover letter. Please try again.');
        setIsPolling(false);
      }
    } catch (error) {
      console.error('POST call failed: ', error);
      setError('Failed to generate cover letter. Please try again.');
      setIsPolling(false);
    } finally {
      setIsLoading(false);
    }
  }

  // GET Method Retrieve Cover Letter
  const fetchCoverLetter = async () => {
    setIsLoading(true);
    setError('');
    setCoverLetter('');

    try {
      const idToken = await fetchIdToken();

      const restOperation = await get({
        apiName: 'cligAPI',
        path: '/api/retrieve-cover-letter',
        options: {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      });

      const { body } = await restOperation.response;
      const str = await body.text();

      // Check if the response includes the coverLetter property
      if (str) {
        setCoverLetter(str);
      } else {
        setError('Cover letter not found.');
      }
    } catch (error) {
      if (error instanceof ApiError && error.response) {
        const { statusCode, headers } = error.response;
        console.error(`Received ${statusCode} error response with payload: ${headers}`);
        setError('Failed to retrieve the cover letter. Please try again with correct details.');
      } else {
        console.error('GET call failed: ', error);
        setError('Failed to retrieve the cover letter. Please try again.');
      }
    } finally {
      setIsLoading(false);
      setIsPolling(false);
    }
  };

  // Copy cover letter to clipboard
  const copyToClipboard = (str) => {
    navigator.clipboard.writeText(str).then(() => {
      setCopySuccess("Cover letter copied to clipboard!");
      setTimeout(() => setCopySuccess(''), 3000); // Clear message after 3 seconds
    }, err => {
      console.error('Could not copy text: ', err);
      setCopySuccess("Failed to copy to clipboard.");
      setTimeout(() => setCopySuccess(''), 3000); // Clear message after 3 seconds
    });
  };

  // Toggle function for displaying the Privacy Policy modal
  const togglePrivacyPolicy = () => {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  };

  // Convert line breaks in cover letter text for display
  const formattedCoverLetter = coverLetter.split('\n\n').map((paragraph, index) => (
    <React.Fragment key={index}>{paragraph}<br /><br /></React.Fragment>
  ));

  return (
    <Router>
      <div className="App">
        <header className="header">
          {user ? (
            <button onClick={handleSignOut} className="button" aria-label="Sign out of your account">Sign Out</button>
          ) : (
            <button onClick={() => setShowModal(true)} className="button" aria-label="Open login or registration modal">Login / Register</button>
          )}
        </header>

        {showModal && (
          <Modal onClose={() => setShowModal(false)}>
            <Authenticator>
              {({ signOut, user }) => {
                if (user) {
                  window.location.reload();
                }
                return (
                  <>
                    <h1>Welcome, {user?.username}</h1>
                    <button onClick={() => { signOut(); setShowModal(false); }} className="button">Sign Out</button>
                  </>
                );
              }}
            </Authenticator>
            <button onClick={() => setShowModal(false)} className="button">Close</button>
          </Modal>
        )}

        <div style={{ textAlign: 'center' }}>
          <img src={`${process.env.PUBLIC_URL}/icons/icon-512x512.png`} className="App-logo" alt="CLIG Logo" />
        </div>

        <div className="description">
          <p>Discover clig.ai, your AI-powered assistant for crafting standout cover letters. Here's how to use it:</p>
          <p>1. Copy your CV and the job description. </p>
          <p>2. Paste them into the respective fields on clig.ai.</p>
          <p>3. Click the "Generate Cover Letter" button <strong>THIS IS A TEST VERSION, PLEASE REPORT BUGS TO: contact@clig.ai</strong>.</p>
          <button onClick={toggleDescription} className="button">{isExpanded ? 'Read Less' : 'Read More'}</button>
          {isExpanded && (
            <div className="full-description">
              <p>Our AI delves deep into your documents, highlighting your qualifications and aligning your strengths with job requirements. It crafts a personalized draft by integrating crucial keywords and reflecting your unique story. With clig.ai, transform your job application process and ensure your cover letter captures the essence of who you are, making you a memorable candidate. Embrace the future of job applications with clig.ai and focus on what's important: landing your dream job.</p>
            </div>
          )}
        </div>

        <Routes>
          <Route path="/" element={<InputForm onSubmit={generateCoverLetter} isUserLoggedIn={!!user} />} />
        </Routes>

        {isLoading && <div className='loading'>Loading...this may take a minute or two</div>}
        {error && <div className='error'>{error}</div>}
        
        {/* <StripeButton />

        !hasPaid && <button onClick={handleCheckout} className="button">Pay for Cover Letter</button> */}
        
        {coverLetter && !copySuccess && (
          <div className='cover-letter'>
            <button onClick={() => setCoverLetter('')} className="button">Close</button>
            <h2>Your Cover Letter: </h2>
            <div>{formattedCoverLetter}</div>
            <button onClick={() => copyToClipboard(coverLetter)} className="button">Copy to Clipboard</button>
            {copySuccess && <p className="copy-success-message">{copySuccess}</p>}
          </div>
        )}
        
        <footer className="footer">
          <a href="#!" onClick={togglePrivacyPolicy}>Privacy Policy</a>
        </footer>

        {showPrivacyPolicy && (
          <Modal onClose={() => setShowPrivacyPolicy(false)}>
            <div>
              <PrivacyPolicy />
              <button onClick={() => setShowPrivacyPolicy(false)} className="privacy-policy-close-button">Close</button>
            </div>
          </Modal>
        )}
      </div>
    </Router>
  );
};

export default App;
