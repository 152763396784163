import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const session = await fetchAuthSession();
        const user = { idToken: session.tokens?.idToken?.toString() };
        if (user.idToken) {
          setUser(user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching auth session:', error);
        setUser(null);
      }
    };

    checkUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};