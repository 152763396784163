import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-content">
      <h2>Privacy Policy</h2>
      <p>Last updated: 21/03/2024</p>
      
      <p>Welcome to clig.ai! Your privacy is important to us. This Privacy Policy explains how we collect, use, protect, and share information about you, along with your rights and choices about such uses and disclosures.</p>
      
      <h3>Information We Collect</h3>
      <ul>
        <li>Personal Information: We collect personal information you provide to us, such as your name, email address, and payment information when you use Amazon Pay or any other payment method to access our services.</li>
        <li>Usage Information: Details of your interactions with our service, including, but not limited to, IP address, device information, and viewed pages.</li>
        <li>Cookies and Tracking Technologies: We use cookies and similar tracking technologies to track activity on our service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.</li>
      </ul>
      
      <h3>How We Use Your Information</h3>
      <p>To process your transactions and provide you with the services requested.</p>
      <p>To communicate with you about your account or transactions and send you information about features and enhancements.</p>
      <p>To enhance and improve our services and for analytics purposes.</p>
      
      <h3>Sharing of Information</h3>
      <p>We do not sell, rent, or otherwise share your personal information with third parties, except as necessary for the provision of our services or as required by law.</p>
      
      <h3>Your Choices</h3>
      <p>You can review and change your personal information by contacting us at contact@clig.ai.</p>
      <p>You may opt out of receiving promotional emails from us by following the instructions in those emails.</p>
      <p>If you are a resident of the European Union or California, you have additional rights as outlined in the sections below.</p>
      
      <h3>GDPR Compliance</h3>
      <p>If you are a resident of the European Union, you have certain data protection rights. clig.ai aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
      
      <h3>CCPA Compliance</h3>
      <p>If you are a resident of California, you have certain rights under the California Consumer Privacy Act. To exercise these rights, please contact us.</p>
      
      <h3>Security</h3>
      <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure.</p>
      
      <h3>Changes to This Privacy Policy</h3>
      <p>We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
      
      <h3>Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at contact@clig.ai.</p>
    </div>
  );
};

export default PrivacyPolicy;
