import React, { useState } from 'react';
import '../App.css';

const InputForm = ({ onSubmit, isUserLoggedIn }) => {
  const [cvText, setCvText] = useState('');
  const [jobDesc, setJobDesc] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ cvText, jobDesc });
  };

return (
  <form onSubmit={handleSubmit} className="input-form">
    <div className="input-group">
      <textarea
        id="cvText"
        className="wide-input"
        value={cvText}
        onChange={(e) => setCvText(e.target.value)}
        placeholder="CV Text"
        required
      />
    </div>
    <div className="input-group">
      <textarea
        id="jobDesc"
        className="wide-input"
        value={jobDesc}
        onChange={(e) => setJobDesc(e.target.value)}
        placeholder="Job Description"
        required
      />
    </div>
    <button 
        type="submit" 
        className="button" 
        disabled={!isUserLoggedIn}
      >
        {isUserLoggedIn ? 'Generate Cover Letter' : 'Please Log in or Register'}
      </button>
    </form>
  );
};

export default InputForm;
