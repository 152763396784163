import React, { useRef, useEffect } from 'react';

const Modal = ({ onClose, children }) => {
  const modalContentRef = useRef(null);

  useEffect(() => {
    // Prevent background scroll
    document.body.style.overflow = 'hidden';
    
    const handleClickOutside = (event) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        onClose(); // Close modal
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // Re-enable background scroll
      document.body.style.overflow = 'unset';
    };
  }, [onClose]);

  return (
    <div className="modal-backdrop show" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()} ref={modalContentRef}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
